import React, { useState } from "react";
import {
  useContractRequest,
  useContractsRequest
} from "../../Hooks/contracts/useContracts.query";
import { Flex, Divider, Card, Table, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import backgroundColumnTable from "../../helpers/backgroundColumnTable";

const Contracts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading } = useContractsRequest();

  const columns = [
    {
      title: "Contrato ID",
      dataIndex: "contract_number",
      key: "contract_number",
      width: "15%",
      render: (text, record) => {
        return backgroundColumnTable(
          record?.is_new,
          text,
          "#FFCB9A77",
          "#D2E8E300"
        );
      }
    },
    {
      title: "Cliente",
      dataIndex: "profile",
      key: "profile",
      render: (text, record) => {
        return backgroundColumnTable(
          record?.is_new,
          React.createElement(
            Link,
            { to: `/investidor/perfil/${text?.id}/informacoes-pessoais` },
            text?.name
          ),
          "#FFCB9A77",
          "#D2E8E300"
        );
      }
    },
    {
      title: "Status",
      key: "status_ZPS",
      render: (text, record) => {
        return backgroundColumnTable(
          record?.is_new,
          React.createElement(
            "p",
            null,
            !text?.status_ZPS ? "Não enviado" : `${text?.status_ZPS}`
          ),
          "#FFCB9A77",
          "#D2E8E300"
        );
      }
    },
    {
      title: "Visualizar",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (text) =>
        React.createElement(
          Link,
          { to: `/contratos/${text}/detalhes` },
          "Visualizar"
        )
    }
  ];

  const handleOpenModalContract = (e) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center"></Flex>
      <Link to={"novo"}>novo contrato</Link>
      <Divider />
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Contratos"
      >
        <Table
          tableLayout="auto"
          loading={isLoading ? true : false}
          columns={columns}
          dataSource={data}
          pagination={false}
          className="ant-border-space custom-row-hover"
          bordered
          rowClassName={(record) =>
            record?.is_new ? "new-row fade-in" : "old-row"
          }
        />

        <Modal
          title=""
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
          maskClosable={false}
          okText="Tem certeza?"
          confirmLoading
          maskStyle={{ backdropFilter: "10px" }}
          footer={true}
        ></Modal>
      </Card>
    </div>
  );
};

export default Contracts;
