import api from "../../services/api";
import { useQuery } from "@tanstack/react-query";

const getMaterials = async () => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/v1/materials`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data?.data;
  } catch (error) {
    console.log(error.message);
  }
};

const postMaterial = async (values) => {
  try {
    const data = await api.post(
      `${process.env.REACT_APP_API}/v1/materials/create`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const putMaterial = async (values) => {
  try {
    const data = await api.post(
      `${process.env.REACT_APP_API}/v1/materials/update?id=${values.id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useMaterialsFetchRequest() {
  return useQuery(["materials"], () => getMaterials(), {
    onSuccess: (data) => {
      return data;
    }
  });
}

export function useMaterialPost(data) {
  return postMaterial(data);
}


export function useUpdateMaterial(data) {
  return putMaterial(data);
}