import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";

const getResources = async (controller, action, value) => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/v1/${controller}/${action}`,
      value,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    console.table(data);
    return data;
  } catch (error) {
    console.log(`Error on ${controller}/${action}`, error.response.status);
    return error;
  }
};

const getResource = async (controller, action, value) => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/v1/${controller}/${action}?id=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(`Error on ${controller}/${action}`, error.response.status);
    return error;
  }
};

/**
 * @param {string} queryKey     - key to be used in the query
 * @param {string} controller   - controller name
 * @param {string} action       - action name
 * @param {object} value        - value to be passed in the query
 * @param {int} enabled         - enable the query
 * @returns {object}            - data from the query
 */
export function useGetResources(queryKey, controller, action, value, enabled) {
  return useQuery({
    queryKey,
    queryFn: () => getResources(controller, action, value),
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      console.log("error:", err);
    },
    refetchOnWindowFocus: false,
    enabled: !!enabled
  });
}

/**
 * @param {string} queryKey     - key to be used in the query
 * @param {string} controller   - controller name
 * @param {string} action       - action name
 * @param {int} value           - value to be passed in the query ID
 * @param {int} enabled         - enable the query
 * @returns {object}            - data from the query
 */
export function useGetResource(queryKey, controller, action, value, enabled) {
  return useQuery({
    queryKey,
    queryFn: () => getResource(controller, action, value),
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      console.log("error:", err);
    },
    refetchOnWindowFocus: false,
    enabled: !!enabled
  });
}
