import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/forms";
import { useResourcePost } from "../../Hooks/post/usePost.query";
import { Button, notification } from "antd";
import DefaultUpload from "../../components/uploads/DefaultUpload";
import SendContract from "./sendContract";
import { useGetResource } from "../../Hooks/get/useGet.query";

const fields = [
  { name: "name", label: "Nome", type: "text", required: true },
  { name: "email", label: "E-mail", type: "text", required: true },
  {
    name: "whatsapp",
    label: "Whatsapp",
    type: "text",
    mask: "telefone",
    required: true
  },
  {
    name: "phone",
    label: "Telefone",
    type: "text",
    mask: "telefone",
    required: true
  },
  {
    name: "postal_code",
    label: "CEP",
    type: "text",
    mask: "cep",
    required: true
  },
  { name: "street", label: "Rua", type: "text", required: true },
  { name: "number", label: "Numero", type: "text", required: true },
  { name: "city", label: "Cidade", type: "text", required: true },
  { name: "state", label: "Estado", type: "text", required: true },
  { name: "neighborhood", label: "Bairro", type: "text", required: true },
  { name: "complement", label: "Complemento", type: "text", required: true },
  {
    name: "number_cpf",
    label: "Numero CPF",
    type: "text",
    mask: "cpf",
    required: true
  },
  {
    name: "number_rg",
    label: "Numero RG",
    type: "text",
    mask: "rg",
    required: true
  },
  {
    name: "number_cnh",
    label: "Numero CNH",
    type: "text",
    mask: "cnh",
    required: true
  },
  {
    name: "marital_status",
    label: "Estado Civil",
    type: "text",
    required: true
  },
  { name: "profession", label: "Profissão", type: "text", required: true },
  { name: "nationality", label: "Nacionalidade", type: "text", required: true },
  { name: "plan", label: "Plano", type: "selectplan", required: true },
  {
    name: "contract_amount",
    label: "Valor de aporte",
    type: "amount",
    required: true
  }
];

const NewClient = () => {
  const [api, contextHolder] = notification.useNotification();

  const [callbackResponse, setCallbackResponse] = React.useState(null);
  const [openSendDocuments, setOpenSendDocuments] = useState(false);
  const [user, setUser] = useState(false);
  const [docCpf, setDocCpf] = useState("");
  const [docRg, setDocRg] = useState("");
  const [docCnh, setDocCnh] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [openContract, setOpenContract] = useState(false);
  const [idUser, setIdUser] = useState("");

  const { data: userContract, isLoading: loadingUser } = useGetResource(
    "profile",
    "user",
    "view",
    idUser,
    idUser
  );

  const { mutateAsync, isPending } = useResourcePost(
    "profile",
    "profile",
    "register-customer",
    (data) => {
      setOpenSendDocuments(true);
      setUser(data);
      api.open({
        message: "Sucesso",
        description: data.message
      });
    },
    (err) => {
      console.log("ERROR:", err);
      api.open({
        message: "erro",
        description: `erro ao criar ${err.message}`
      });
    }
  );
  const { mutateAsync: sendDoc, isPending: sendingDoc } = useResourcePost(
    "profile",
    "profile",
    "upload-doc",
    (data) => {
      api.open({
        message: "Sucesso",
        description: data.message
      });
      setOpenContract(true);
    },
    (err) => {
      api.open({
        message: "erro",
        description: `erro ao criar ${err.message}`
      });
    }
  );

  const handleSubmit = (data) => {
    const send = {
      username: data.number_cpf,
      ...data
    };
    mutateAsync(send);
    setNameUser(send.name);
    setIdUser(send);
  };

  useEffect(() => {}, [setUser, setNameUser, setIdUser]);

  const handleUploadCpf = (data) => {
    setDocCpf(data.imageUrl);
  };
  const handleUploadCnh = (data) => {
    setDocCnh(data.imageUrl);
  };
  const handleUploadRg = (data) => {
    setDocRg(data.imageUrl);
  };

  const handleSendUploadsApi = (data) => {
    let dataSend = {
      id: user.id,
      cpf: docCpf,
      rg: docRg,
      cnh: docCnh
    };
    sendDoc(dataSend);
    // setIdUser(user.id);
  };
  console.log("<<<>>>", userContract);

  return (
    <>
      {contextHolder}

      {!openContract ? (
        openSendDocuments ? (
          <>
            <DefaultUpload
              label={"Anexar CPF"}
              title={`${nameUser}_cpf`}
              folder={`documentation_activation_account/${user?.id}/cpf`}
              callback={handleUploadCpf}
            />
            <DefaultUpload
              label={"Anexar CNH"}
              title={`${nameUser}_cnh`}
              folder={`documentation_activation_account/${user?.id}/cnh`}
              callback={handleUploadCnh}
            />
            <DefaultUpload
              label={"Anexar Rg"}
              title={`${nameUser}_rg`}
              folder={`documentation_activation_account/${user?.id}/rg`}
              callback={handleUploadRg}
            />
            <Button loading={loadingUser} onClick={handleSendUploadsApi}>
              Ok
            </Button>
          </>
        ) : (
          <DynamicForm
            fields={fields}
            onSubmit={handleSubmit}
            callbackResponse={callbackResponse}
            setCallbackResponse={setCallbackResponse}
          />
        )
      ) : (
        <SendContract data={idUser} />
      )}
    </>
  );
};
export default NewClient;
