import React, { useState } from "react";
import { useMaterialsFetchRequest } from "../../Hooks/materials/useMaterials.query";
import { Col, Button, Card, Table, Modal, Image } from "antd";
import { NavLink } from "react-router-dom";

const Materials = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [dataModalEdit, setDataModalEdit] = useState({});

  const { data, isLoading } = useMaterialsFetchRequest();

  const showModalEdit = (e) => {
    setIsModalOpenEdit(true);
    setDataModalEdit(e);
  };

  const showModal = (e) => {
    setIsModalOpen(true);
    console.log(e);
    setDataModal(e);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Visualizar",
      dataIndex: "ver",
      key: "ver",
      render: (text, record) => (
        <Button onClick={() => showModal(record)}>Visualizar</Button>
      )
    },
    {
      title: "Editar",
      dataIndex: "edit",
      key: "ver",
      render: (_, record) => (
        <>
          <Button type="primary" className="">
            <NavLink to={`editar/${record.id}`}>Editar </NavLink>
          </Button>
        </>
      )
    }
  ];

  return (
    <div className="layout-content">
      <Col>
        <Button type="primary" className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Col>

      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Investidores"
      >
        <div className="table-responsive">
          <Table
            loading={isLoading ? true : false}
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            className="ant-border-space custom-row-hover"
          />
        </div>
      </Card>

      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {dataModal.title} - {dataModal.description}
        {dataModal.type}
        <Image width={400} src={dataModal.url_file} />
      </Modal>
    </div>
  );
};

export default Materials;
