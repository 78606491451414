import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../services/api";

const getAccess = async () => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/site/get-access`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return data;
  } catch (error) {
    console.log("error.response.status", error.response.status);
    return error;
  }
};

const getValidToken = async () => {
  try {
    const data = await api.get(`${process.env.REACT_APP_API}/check`, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useAccessFetchRequest() {
  return useQuery(["admin-access"], () => getAccess(), {
    onSuccess: (data) => {
      return data;
    },
    onError: (err) => {
      console.log("error:", err);
    },
    refetchOnWindowFocus: false
  });
}

export function useCheck(id) {
  return useQuery(["check"], () => getValidToken(), {
    onSuccess: (data) => {
      console.log("");
    },
    onError: (err) => {
      console.log("err: ", err);
    },
    refetchOnWindowFocus: false,
    enabled: !!id
  });
}
