import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input, notification, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useGetResource } from '../../Hooks/get/useGet.query';
import DefaultUpload from '../../components/uploads/DefaultUpload';
import { useUpdateMaterial } from '../../Hooks/materials/useMaterials.query';

const { Title } = Typography;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 }
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 }
  };

  
const EditMaterial = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
      const [api, contextHolder] = notification.useNotification();
      const queryClient = useQueryClient();
      const navigate = useNavigate();
      const [post, setPost] = useState(null);
const [callbackResponse, setCallbackResponse] = useState({});
    
      const { data, isLoading} = useGetResource(
        "materials",
        "materials",
        "view",
        id,
        id
      );

      const { mutate, isLoading: sendingForm } = useMutation(useUpdateMaterial, {
          onSuccess: () => {
            queryClient.invalidateQueries("materials");
            api.open({
              message: "Sucesso",
              description: "sucesso."
            });
            // form.resetFields();
            navigate("/materials");
          }
        });

      const handleUploadComplete = (data) => {
        console.log('IMAGE:: ', data)
        setCallbackResponse(data);
      };
    
      const onFinish = (formValues) => {
        const data = {
          ...formValues,
          file: callbackResponse?.imageUrl,
          url_file: callbackResponse?.imageUrl,
          file_type: callbackResponse?.imagekitData?.fileType,
          version_id: callbackResponse?.imagekitData?.versionInfo.id,
          version_name: callbackResponse?.imagekitData?.versionInfo.name,
          name: callbackResponse?.imagekitData?.name,
          id
        };
    
        mutate(data);
      };
    

  return (
    <>
    <Title level={4}>Editar {data?.title}</Title>
    <Form
            {...layout}
            layout="horizontal"
            form={form}
            name="create-user"
            onFinish={onFinish}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            fields={[
              {
                name: ["title"],
                value: data?.title
              },
              {
                name: ["description"],
                value: data?.description
              },
              {
                name: ["type"],
                value: data?.type
              }
            ]}
          >
            <Form.Item name="title" label="Titulo" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
                  name="description"
                  label="Descrição"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="type"
                  label="Tipo"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="file" label="Arquivo">
          <DefaultUpload
            title={data?.name}
            label={`Enviar arquivo`}
            folder={`testes/materials/materials-managers/`}
            controller="material"
            action="uploadmaterials"
            callback={handleUploadComplete}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            // loading={!!sendingForm}
            style={{ width: "100%", marginRight: 0 }}
            type="primary"
            htmlType="submit"
          >
            CRIAR
          </Button>
        </Form.Item>
          </Form>
          {
            data?.file_type === 'image' ? 
            <img src={`${data?.file}?${Math.random() * (1000 - 100) + 100}`} width={250} /> : 
            <a target='_blank' href={data?.file}>
              <img src={`https://play-lh.googleusercontent.com/alwxFqgk_n0RsOfsZGWVRc6SkBJmHDIdVARN7uL7ipceaqHqI_SLaG_QgVIbmmgQXfkv`} width={250} />
            </a>
          }
    </>
  )
}

export default EditMaterial